<template>
  <AppPageWrapper
    :viewport-header="{
      desktopWide: '112px',
      desktopMedium: '48px',
    }"
  >
    <slot name="disclaimer" />
    <AppHeader collection="cs_header" />
      <div class="section-manager-item" style="max-width:1250px;margin:0 auto;background: #055f8c;color:#fff;padding:10px 20px;border-radius:10px;margin-bottom:10px;">
        <div>
          Уважаемый клиент!<br><br>
          Мы восстановили работоспособность всех наших сервисов в полном объеме. Вся сеть АЗС доступна для обслуживания.<br><br>
          Простите за доставленные неудобства. Спасибо за ваше терпение и понимание.<br><br>
          Ваш ППР
        </div>
      </div>
      <slot />
    <AppFooter collection="cs_footer" />
  </AppPageWrapper>
</template>

<script>
{/* <div class="section-manager-item" style="max-width:1250px;margin:0 auto;background: #055f8c;color:#fff;padding:10px 20px;border-radius:10px;margin-bottom:10px;">
  <div>
    Уважаемый клиент!<br>
    Рады сообщить, что все наши сервисы работают стабильно и в полном объеме.<br>
    Спасибо за ваше терпение и понимание. <br>
    Всегда рядом,<br>
    «Передовые Платежные Решения»
  </div>
</div> */}
</script>
